import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import Social from "../Social";
import { COMPANY_SLOGAN, SOCIAL_MEDIA } from "../../constants/CompanyConstants";
import Logo from "../Logo";
import { Link } from "gatsby";

const Footer = ({ isDark = true }) => {
  const Footer = styled.footer`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px 0 40px;
    font-size: 16px;
  `;

  const FooterItemsWrapper = styled.div`
    padding: 0 1.5rem;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
    }
  `;

  const TermsLink = styled(Link)`
    font-size: 12px;
    color: lightgrey !important;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 8px 52px 20px 0;
    text-wrap: nowrap;
    text-decoration: none;
    transition: all 0.3s ease;
  `;

  return (
    <>
      <Footer>
        <Container fluid>
          <FooterItemsWrapper>
            <Logo small onClick={() => window.scrollTo(0, 0)} />
            <CompanySlogan>{COMPANY_SLOGAN}</CompanySlogan>
            <Social items={SOCIAL_MEDIA} />
          </FooterItemsWrapper>
        </Container>
      </Footer>
      <TermsLink to="/algemene-voorwaarden">Algemene voorwaarden</TermsLink>
    </>
  );
};

const CompanySlogan = styled.p`
  font-size: 20px;
  font-weight: 700;
  font-family: "Rubik", sans-serif;
  text-align: center;
  align-self: center;
  margin: 0;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

export default Footer;
